import React, { useEffect, useState } from 'react';
import '../../styles/global.css'
import { Link } from 'gatsby'
import {Helmet} from "react-helmet";
import Particles from "react-tsparticles";
import Slide from 'react-reveal/Slide' // Importing Zoom effect

export default function Tester() {

  const [showModal, setShowModal] = React.useState(true);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showModal3, setShowModal3] = React.useState(false);
  const [showModal4, setShowModal4] = React.useState(false);


  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };


  const [List, setList] = useState([]);

  const handleClick = () => {
    setList([...List, { title: "Vision and Strategy", subtitle: "Snazzy Tagline", challenge: "The rising price of fredos", solution: "Don't vote tory", previous: "Fermanagh Futures - link", image: "/img/projects/light and artwork_without sign.webp"}]);
    setShowModal2(true);
  };

  const handleClick2 = () => {
    setList([...List, { title: "Sustainable Business Model", subtitle: "I'm dynamic!", challenge: "Joe Rogan", solution: "Listen to something else", previous: "Strangford - link", image: "/img/projects/banana-grid2.png"}]);
    setShowModal3(true);
  };

  const handleClick3 = () => {
    setList([...List, { title: "Brand Impact", subtitle: "See I can change!", challenge: "The existence of Larne", solution: "Just go to the Winchester", previous: "AMTCE - link", image: "/img/projects/after.jpg"}]);
    setShowModal4(true);
  };

  console.log(List);
  return (

    
    <div>

<Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#192958",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 20,
              opacity: 0.8,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 100,
            enable: true,
            opacity: 0.2,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.3,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 15,
          },
        },
        detectRetina: true,
      }}
    />


      {showModal ? (
        <Slide right>
          <div
            className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
          > 
            <div className="relative w-screen h-screen mx-auto">       
              <div className="modal-branding border-0 relative flex flex-col justify-between w-full h-full outline-none focus:outline-none">
                <div>
                  <Link to="../branding" className='h-12 w-12 m-6 float-right'>
                   <img src="/img/icons/question-mark.png"></img>
                  </Link>
                  <Link to="../start" className='float-left text-blue-900 bg-gray-100 hover:text-white hover:bg-blue-900 px-6 py-2 m-6 outline-none focus:outline-none ease-linear transition-all duration-150'>
                    <p className='font-heading font-semibold uppercase font-xl'>START</p>
                  </Link>
                </div>
                
            
                {/*body*/}

                <div className='modal-question grid grid-rows-2 text-center md:w-7/12 mx-auto text-white'>
                  <div className='border-b-2 border-white'>
                    <p className='p-6 text-3xl'>Do you already have a vision and strategy?
                    </p>
                  </div>
                  <div className='grid grid-cols-2'>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6 border-r-2 border-white'
                       onClick={() => setShowModal2(true)}>
                        <p className='text-white font-semibold text-4xl font-heading'>YES</p>
                      </button>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6'
                       onClick={handleClick}>
                        <p className='text-white font-semibold text-4xl font-heading'>NO</p>
                      </button>
                  </div>
                </div>
    
                {/*footer*/}
         

                <div>
                  <Link to="https://urbanscaleinterventions.com/" className='h-12 w-16 m-6 float-right'>
                   <img src="https://ik.imagekit.io/5y988hvt8sd/USI/usi_jPyuDpBGj.png?updatedAt=1638527502632"></img>
                  </Link>

                  <Link to="/tester"
                    className="float-left text-blue-900 bg-white hover:text-white hover:bg-blue-900 font-heading font-semibold uppercase font-xl px-6 py-2 m-6 outline-none focus:outline-none ease-linear transition-all duration-150"
                  >
                    BACK
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </Slide>
      ) : null}


  {showModal2 ? (
        <Slide right>
          <div
            className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
          >
            <div className="relative w-screen h-screen mx-auto">       
              <div className="modal-branding-2 border-0 relative flex flex-col justify-between w-full h-full outline-none focus:outline-none">
                <div>
                  <Link to="../branding" className='h-12 w-12 m-6 float-right'>
                   <img src="/img/icons/question-mark.png"></img>
                  </Link>
                  <Link to="../start" className='float-left text-blue-900 bg-gray-100 hover:text-white hover:bg-blue-900 px-6 py-2 m-6 outline-none focus:outline-none ease-linear transition-all duration-150'>
                    <p className='font-heading font-semibold uppercase font-xl'>START</p>
                  </Link>
                </div>
                
            
                {/*body*/}

                <div className='modal-question grid grid-rows-2 text-center md:w-7/12 mx-auto text-white'>
                  <div className='border-b-2 border-white'>
                    <p className='p-6 text-3xl'>Do you have a sustainable business model?
                    </p>
                  </div>
                  <div className='grid grid-cols-2'>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6 border-r-2 border-white'
                       onClick={() => setShowModal3(true)}>
                        <p className='text-white font-semibold text-4xl font-heading'>YES</p>
                      </button>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6'
                      onClick={handleClick2}>
                        <p className='text-white font-semibold text-4xl font-heading'>NO</p>
                      </button>
                  </div>
                </div>
    
                {/*footer*/}
         

                <div>
                  <Link to="https://urbanscaleinterventions.com/" className='h-12 w-16 m-6 float-right'>
                   <img src="https://ik.imagekit.io/5y988hvt8sd/USI/usi_jPyuDpBGj.png?updatedAt=1638527502632"></img>
                  </Link>

                  <button
                    className="float-left text-blue-900 bg-white hover:text-white hover:bg-blue-900 font-heading font-semibold uppercase font-xl px-6 py-2 m-6 outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal3(true)}
                  >
                    SKIP
                  </button>
                </div>

              </div>
            </div>
          </div>
        </Slide>
      ) : null}



  {showModal3 ? (
        <Slide right>
          <div
            className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
          >
            <div className="relative w-screen h-screen mx-auto">       
              <div className="modal-branding-3 border-0 relative flex flex-col justify-between w-full h-full outline-none focus:outline-none">
                <div>
                  <Link to="../branding" className='h-12 w-12 m-6 float-right'>
                   <img src="/img/icons/question-mark.png"></img>
                  </Link>
                  <Link to="../start" className='float-left text-blue-900 bg-gray-100 hover:text-white hover:bg-blue-900 px-6 py-2 m-6 outline-none focus:outline-none ease-linear transition-all duration-150'>
                    <p className='font-heading font-semibold uppercase font-xl'>START</p>
                  </Link>
                </div>
                
            
                {/*body*/}

                <div className='modal-question grid grid-rows-2 text-center md:w-7/12 mx-auto text-white'>
                  <div className='border-b-2 border-white'>
                    <p className='p-6 text-3xl'>Do you know how your brand can be impactful?
                    </p>
                  </div>
                  <div className='grid grid-cols-2'>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6 border-r-2 border-white'
                       onClick={() => setShowModal4(true)}>
                        <p className='text-white font-semibold text-4xl font-heading'>YES</p>
                      </button>
                      <button className='hover:bg-gray-900 transition duration-500 ease-in-out transform hover:scale-100 motion-reduce:transform-none p-6'
                      onClick={handleClick3}>
                        <p className='text-white font-semibold text-4xl font-heading'>NO</p>
                      </button>
                  </div>
                </div>
    
                {/*footer*/}
         

                <div>
                  <Link to="https://urbanscaleinterventions.com/" className='h-12 w-16 m-6 float-right'>
                   <img src="https://ik.imagekit.io/5y988hvt8sd/USI/usi_jPyuDpBGj.png?updatedAt=1638527502632"></img>
                  </Link>

                  <button
                    className="float-left text-blue-900 bg-white hover:text-white hover:bg-blue-900 font-heading font-semibold uppercase font-xl px-6 py-2 m-6 outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal4(true)}
                  >
                    SKIP
                  </button>
                </div>

              </div>
            </div>
          </div>
        </Slide>
      ) : null}



  {showModal4 ? (
        <Slide right>
          <div
            className="modal justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
          >
            <div className="answers-page relative w-screen h-auto mx-auto">       
              <div className=" border-0 relative w-full h-full outline-none focus:outline-none">
                <div>
                  <Link to="../branding" className='h-12 w-12 m-6 float-right'>
                   <img src="/img/icons/question-mark.png"></img>
                  </Link>
                  <Link to="../start" className='float-left text-blue-900 bg-gray-100 hover:text-white hover:bg-blue-900 px-6 py-2 m-6 outline-none focus:outline-none ease-linear transition-all duration-150'>
                    <p className='font-heading font-semibold uppercase font-xl'>START</p>
                  </Link>
                </div>
                
            
                {/*body*/}

              <div className='mx-auto md:w-10/12 py-16'>
                <div className='text-left w-full mx-auto text-white'>
                  <div className=' w-1/2'>
                    <p className='p-6 text-6xl border-b-2 border-white'>It sounds like you need help with
                    </p>
                  </div>
                </div>
              
                <div className=''>
                      {List.map((x, i) => (
                      <div className='grid grid-cols-1 md:grid-cols-2 gap-16 p-6 mx-auto'>
                       <div className='text-left w-full mx-auto text-white' key={i}>
                        {" "}
                        <p className='text-7xl font-heading pb-6'> {x.title}</p>{" "}
                        <p className='text-xl font-sans pb-6'> {x.subtitle} </p>
                        <p className='text-xl font-sans pb-6'>Previous Project: {x.previous} </p>
                       </div>
                        <div>
                          <img className='w-full' src={x.image}></img>
                        </div>
                      </div>
                       ))}
                </div>
              </div>

                <div className='mx-auto md:w-10/12 py-16'>
                    <h3 className='text-white text-6xl pb-6 font-heading'>
                      Challenges
                    </h3>
                    <ul className='challenges-list text-white text-3xl list-inside'>
                    {List.map((x, i) => (
                     <div key={i}>
                      <li className='pb-4'><span class="inline-block align-top pl-4"> {x.challenge}</span></li>
                    </div>
                    ))} 
                    </ul>
                </div>


              <div className='text-right w-full mx-auto bg-white text-blue-new relative'>
                <div className='mx-auto md:w-10/12 py-16'>
                  <div className='grid grid-cols-1 md:grid-cols-2'>
                    <div></div>
                    <p className='p-6 text-6xl border-b-2 border-blue-900'>Well don't worry we have plenty of experience
                    </p>
                  </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-16 p-6 mx-auto'>
                  <div>
                    <img src="/img/projects/portview.png"></img>
                  </div>
                  <div className='text-left'>
                    <h3 className='text-blue-new text-6xl pb-6 font-heading'>
                      Solutions
                    </h3>
                    <ul className='challenges-list-blue text-blue-new text-3xl list-inside'>
                    {List.map((x, i) => (
            <div key={i}>
              <li className='pb-4'><span class="inline-block align-top pl-4"> {x.solution}</span></li>
            </div>
          ))}
                    </ul>
                  </div>
                </div>
                <div className="py-12 px-6 text-left">
                  <Link to="/services" className="marrow marrow-black text-xs md:text-sm text-blue-new">A full list of
                   the services we
                   provide
                     <span></span>
                  </Link>
                </div>      
                </div>
                </div>

                <div className='w-full'>
                  <img className='w-full' src="/img/projects/banana-block-camera-shot.png"></img>
                </div>

    <div class="">
      <div class="container mx-auto px-6 lg:px-0 w-6/6 lg:w-5/6 py-24">
        <form class="w-full px-6 md:px-10 py-6 bg-white bg-opacity-80"
        action="https://formspree.io/f/mnqwjzqe"
        method="POST"
        >
        <h3 class="text-3xl py-6 text-blue-900 font-extrabold">Get in contact if it sounds like we can help</h3>
        <div class="items-center w-6/6 border-b border-blue-900 py-6">
          <label class="font-bold">
           Your email:
          <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="email" name="_replyto"/>
          </label>
        </div>
        <div class="items-center w-6/6 border-b border-gray-700 py-6">
          <label class="font-bold">
            Your comment:
            <textarea class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
            name="message"></textarea>
          </label>
        </div>
        <div class="items-center w-6/6 py-4">
          <button class="flex-shrink-0 bg-blue-700 flex flex-end hover:white hover:text-blue-300 text-white font-bold px-4 py-2 rounded" 
          type="submit">Send</button>
        </div>
        </form>
      </div>
    </div>

               <div>
                  <button
                    className="text-blue-900 bg-white hover:text-white hover:bg-blue-900 font-heading font-semibold uppercase font-xl px-6 py-2 m-6 outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal4(false)}
                  >
                    BACK
                  </button>
                </div>

              </div>
            </div>
          </div>
        </Slide>
      ) : null}
    </div>
  )
  }
  
  